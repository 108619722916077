<template>
  <div
    class="program"
    :style="{ height: clientHeight - 180 + 'px' }"
    style="overflow-y: scroll"
  >
    <!-- 列表 -->
    <div class="programlist">
      <!-- 上 -->
      <div style="display: flex; margin-bottom: 5px">
        <el-form :model="SerachInput" label-width="90px">
          <div style="display: flex; flex-wrap: wrap; align-items: center">
            <el-form-item label="创建时间" class="timeRight">
              <el-date-picker
                v-model="overallForm.time"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="企业编号" class="timeRightOne">
              <el-input
                v-model="SerachInput.number"
                placeholder="请输入企业编号"
                style="width: 150px"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="操作账号" class="timeRightOne">
              <el-input
                v-model="SerachInput.paymentUserPhone"
                placeholder="请输入操作账号"
                style="width: 150px"
                clearable
              ></el-input>
            </el-form-item>
          </div>
          <div style="display: flex">
            <el-form-item label="订单号" class="timeRightOne1">
              <el-input
                v-model="SerachInput.orderNo"
                placeholder="请输入订单号"
                style="width: 250px"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="支付方式" class="timeRight">
              <el-select
                v-model="SerachInput.type"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in opStatus"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="订单状态">
              <el-select
                v-model="SerachInput.state"
                placeholder="请选择"
                clearable
              >
                <el-option key="" label="全部" value="" />
                <el-option
                  v-for="item in opProduct"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <img
              src="../../assets/images/query.png"
              alt=""
              @click="imaClick"
              style="
                width: 49px;
                height: 29px;
                margin: 6px;
                margin-top: 5px;
                margin-left: 10px;
              "
            />
          </div>
        </el-form>
      </div>
      <!-- table -->
      <div class="tables">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column
            prop="orderNo"
            label="编号"
            width="220px"
          ></el-table-column>
          <el-table-column
            prop="dateTime"
            label="充值时间"
            width="160px"
          ></el-table-column>
          <el-table-column
            prop="updateDate"
            label="更新时间"
            width="160px"
          ></el-table-column>
          <el-table-column
            prop="paymentUserPhone"
            label="提交账号"
            width="120px"
          ></el-table-column>
          <el-table-column
            prop="paymentUserName"
            label="提交姓名"
            width="140px"
          ></el-table-column>
          <el-table-column
            prop="groupName"
            label="销售分组"
            width="140px"
          ></el-table-column>
          <el-table-column
            prop="number"
            label="企业编号"
            width="150px"
          ></el-table-column>
          <el-table-column
            prop="type"
            label="充值方式"
            width="110px"
          ></el-table-column>
          <el-table-column prop="rechargeAmount" label="金额" width="120px">
            <template slot-scope="scope">
              <span
                ><span class="qian">¥</span>{{ scope.row.rechargeAmount }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="state"
            label="订单状态"
            width="110px"
          ></el-table-column>
          <el-table-column label="操作" width="120px">
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="handleDelete(scope.$index, scope.row)"
                >订单处理</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="bottom">
        <div class="bottom-left">
          <div
            style="
              margin-left: 25px;
              margin-bottom: 15px;
              font-size: 16px;
              color: #606266;
            "
          >
            查询订单总数:{{ totalCount }}
            <span class="amount">查询订单金额:¥{{ amount }}</span>
          </div>
        </div>
        <div class="bottom-right">
          <div class="bottom-fen">
            <!-- 分页 -->
            <div class="pages">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-size="pageSize"
                :current-page="pageCount"
                layout="total, prev, pager, next"
                :total="totalCount"
              >
              </el-pagination>
            </div>
          </div>
          <div class="buttons">
            <el-button @click="exportList">数据导出</el-button>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="52%"
      :before-close="handleClose"
      :close-on-click-modal="false"
      v-if="isWeChat"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="auto">
        <el-row class="police">
          <el-col :span="9">
            <el-form-item label="订单编号" class="senOne">
              <el-input
                v-model="form.orderNo"
                placeholder="请输入订单编号"
                :disabled="true"
                @input="$forceUpdate()"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="提交账号" class="senOne">
              <el-input
                v-model="form.paymentUserPhone"
                placeholder="请输入提交账号"
                :disabled="true"
                @input="$forceUpdate()"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="企业编号" class="senOne">
              <el-input
                v-model="form.number"
                placeholder="请输入企业编号"
                :disabled="true"
                @input="$forceUpdate()"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="police">
          <el-col :span="9">
            <el-form-item label="充值时间" class="senOne">
              <el-input
                v-model="form.dateTime"
                :disabled="true"
                @input="$forceUpdate()"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="支付方式" class="senOne">
              <el-input
                v-model="form.type"
                placeholder="请输入支付方式"
                :disabled="true"
                @input="$forceUpdate()"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="充值金额" class="senOne">
              <el-input
                v-model="form.rechargeAmount"
                placeholder="请输入充值金额"
                :disabled="true"
                @input="$forceUpdate()"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="police">
          <el-col :span="10">
            <el-form-item label="支付单号" class="senOne">
              <el-input
                v-model="form.transactionId"
                placeholder="请输入支付单号"
                :disabled="true"
                @input="$forceUpdate()"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="支付状态" class="senOne">
              <el-input
                v-model="form.state"
                placeholder="请输入支付状态"
                :disabled="true"
                @input="$forceUpdate()"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="clearUserForm">取 消</el-button>
        <!-- <el-button type="primary" @click="unSubmit">保 存</el-button> -->
      </span>
    </el-dialog>
    <el-dialog
      title=""
      :visible.sync="dialogVisibleS"
      width="52%"
      :before-close="handleCloseS"
      :close-on-click-modal="false"
      v-else
    >
      <el-form ref="formS" :model="formS" label-width="auto">
        <el-row class="police">
          <el-col :span="9">
            <el-form-item label="订单编号" class="senOne">
              <el-input
                v-model="formS.orderNo"
                placeholder="请输入订单编号"
                :disabled="true"
                @input="$forceUpdate()"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="提交账号" class="senOne">
              <el-input
                v-model="formS.paymentUserPhone"
                placeholder="请输入提交账号"
                :disabled="true"
                @input="$forceUpdate()"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="企业编号" class="senOne">
              <el-input
                v-model="formS.number"
                placeholder="请输入企业编号"
                :disabled="true"
                @input="$forceUpdate()"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="police">
          <el-col :span="9">
            <el-form-item label="充值时间" class="senOne">
              <el-input
                v-model="formS.dateTime"
                :disabled="true"
                @input="$forceUpdate()"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="支付方式" class="senOne">
              <el-input
                v-model="formS.type"
                placeholder="请输入支付方式"
                :disabled="true"
                @input="$forceUpdate()"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="充值金额" class="senOne">
              <el-input
                v-model="formS.rechargeAmount"
                placeholder="请输入充值金额"
                :disabled="true"
                @input="$forceUpdate()"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="police">
          <el-col :span="10">
            <el-form-item label="付款户名" class="senOne">
              <el-input
                v-model="formS.payeeName"
                placeholder="请输入付款户名"
                :disabled="true"
                @input="$forceUpdate()"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="付款账号" class="senOne">
              <el-input
                v-model="formS.payeeAccount"
                placeholder="请输入付款账号"
                :disabled="true"
                @input="$forceUpdate()"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="police">
          <el-col :span="10">
            <el-form-item label="付款凭证" class="senOne">
              <img
                :src="receiptImage"
                alt=""
                class="voucher"
                @click="imgClick(receiptImage)"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="police" v-if="isStatus">
          <el-col :span="10">
            <el-form-item label="变更状态" class="senOne">
              <el-radio-group v-model="radio">
                <el-radio :label="1">未到账</el-radio>
                <el-radio :label="3">取消订单</el-radio>
                <el-radio :label="2">已到账</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="police" v-if="isStatus1">
          <el-col :span="10">
            <el-form-item label="变更状态" class="senOne">
              <el-radio-group v-model="radio1">
                <el-radio :label="2">已到账</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="police" v-if="isStatus2">
          <el-col :span="10">
            <el-form-item label="变更状态" class="senOne">
              <el-radio-group v-model="radio2">
                <el-radio :label="3">已取消</el-radio>
                <el-radio :label="2">已到账</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="police" v-if="isStatus3">
          <el-col :span="10">
            <el-form-item label="变更状态" class="senOne">
              <el-radio-group v-model="radio3">
                <el-radio :label="4">已支付</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="clearUserFormS">取 消</el-button>
        <el-button type="primary" @click="unSubmitS" v-if="isSubmit"
          >保 存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { apiShout } from "../../api1/configShout";
import { api } from "../../api1/config";
export default {
  data() {
    return {
      dialogVisible: false,
      dialogVisibleS: false,
      radio: 1,
      radio1: 3,
      radio2: 3,
      radio3: 3,
      isStatus: true,
      isStatus1: false,
      isStatus2: false,
      isStatus3: false,
      isWeChat: true,
      isSubmit: true,
      form: {
        orderNo: "",
        paymentUserPhone: "",
        number: "",
        dateTime: "",
        type: "",
        rechargeAmount: "",
        transactionId: "",
        state: "",
      },
      formS: {
        orderNo: "",
        paymentUserPhone: "",
        number: "",
        dateTime: "",
        type: "",
        rechargeAmount: "",
        payeeName: "",
        payeeAccount: "",
      },
      receiptImage: "",
      tableData: [],
      rules: {},
      opSituation: [],
      SerachInput: {},
      overallForm: {
        time: [],
      },
      opProduct: [],
      opStatus: [
        {
          code: "",
          name: "全部",
        },
        {
          code: "1",
          name: "对公转账",
        },
        {
          code: "2",
          name: "微信支付",
        },
      ],
      clientHeight: document.body.clientHeight,
      //分页
      pageSize: 10,
      totalCount: 100,
      pageCount: 1,
      amount: "",
      rowId: null,
      radioNum: 0,
    };
  },
  created() {
    this.getList();
    this.situa();
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight;
        that.clientHeight = window.screenHeight;
      })();
    };
  },
  watch: {
    clientHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.clientHeight = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.clientHeight);
          that.timer = false;
        }, 400);
      }
    },
  },
  methods: {
    imgClick(aa) {
      console.log("点击的：", aa);
      const image = new Image();
      image.src = aa;
      // image.src = e.explicitOriginalTarget.src;
      image.onload = () => {
        // 创建弹出层
        const previewContainer = document.createElement("div");
        previewContainer.style.position = "fixed";
        previewContainer.style.top = 0;
        previewContainer.style.bottom = 0;
        previewContainer.style.left = 0;
        previewContainer.style.right = 0;
        previewContainer.style.zIndex = 9999;
        previewContainer.style.backgroundColor = "rgba(0,0,0,0.8)";
        previewContainer.style.display = "flex";
        previewContainer.style.justifyContent = "center";
        previewContainer.style.alignItems = "center";
        document.body.appendChild(previewContainer);
        // 在弹出层中添加图片
        const previewImage = document.createElement("img");
        previewImage.src = aa;
        previewImage.style.maxWidth = "80%";
        previewImage.style.maxHeight = "80%";
        previewContainer.appendChild(previewImage);
        // 点击弹出层，关闭预览
        previewContainer.addEventListener("click", () => {
          document.body.removeChild(previewContainer);
        });
      };
    },
    handleDelete(index, row) {
      console.log("详情：", index, row);
      this.rowId = row.id;
      if (row.type == "微信支付") {
        this.isWeChat = true;
        this.dialogVisible = true;
        apiShout.reOrder({ orderNo: row.orderNo }).then((res) => {
          console.log("微信支付详情：", res.data);
          if (res.data.code == 200) {
            this.form.orderNo = row.orderNo;
            this.form.paymentUserPhone = row.paymentUserPhone;
            this.form.number = row.number;
            this.form.dateTime = row.dateTime;
            this.form.type = row.type;
            this.form.rechargeAmount = row.rechargeAmount;
            this.form.transactionId = res.data.result.transactionId;
            this.form.state = row.state;
          } else {
            this.$message.error(res.data.msg);
          }
        });
      } else {
        this.isWeChat = false;
        this.dialogVisibleS = true;
        apiShout.reTrans({ orderNo: row.orderNo }).then((res) => {
          console.log("对公转账详情：", res.data);
          if (res.data.code == 200) {
            this.formS.orderNo = row.orderNo;
            this.formS.paymentUserPhone = row.paymentUserPhone;
            this.formS.number = row.number;
            this.formS.dateTime = row.dateTime;
            this.formS.type = row.type;
            this.formS.rechargeAmount = row.rechargeAmount;
            if (res.data.result) {
              this.formS.payeeName = res.data.result.payeeName;
              this.formS.payeeAccount = res.data.result.payeeAccount;
              this.receiptImage = res.data.result.receiptImage;
            } else {
              this.formS.payeeName = "";
              this.formS.payeeAccount = "";
              this.receiptImage = "";
            }
            if (row.state == "未到账") {
              this.isStatus1 = false;
              this.isStatus = true;
              this.isStatus2 = false;
              this.isStatus3 = false;
              this.isSubmit = true;
              this.radio = 1;
            } else if (row.state == "已到账") {
              this.isStatus1 = true;
              this.isStatus = false;
              this.isStatus2 = false;
              this.isStatus3 = false;
              this.isSubmit = false;
              this.radio1 = 2;
            } else if (row.state == "已取消") {
              this.isStatus1 = false;
              this.isStatus = false;
              this.isStatus2 = true;
              this.isStatus3 = false;
              this.isSubmit = true;
              this.radio2 = 3;
            } else if (row.state == "已支付") {
              this.isStatus1 = false;
              this.isStatus = false;
              this.isStatus2 = false;
              this.isStatus3 = true;
              this.isSubmit = false;
              this.radio3 = 4;
            }
            // let aa = "未到账";
            // if (aa == "未到账") {
            //   this.isStatus1 = false;
            //   this.isStatus = true;
            //   this.isStatus2 = false;
            //   this.radio = 1;
            // } else if (aa == "已到账") {
            //   this.isStatus1 = true;
            //   this.isStatus = false;
            //   this.isStatus2 = false;
            //   this.radio1 = 2;
            // } else if (aa == "已取消") {
            //   this.isStatus1 = false;
            //   this.isStatus = false;
            //   this.isStatus2 = true;
            //   this.radio2 = 3;
            // }
          } else {
            this.$message.error(res.data.msg);
          }
        });
      }
    },
    unSubmit() {
      this.dialogVisible = false;
    },
    unSubmitS() {
      if (this.isStatus == true) {
        this.radioNum = this.radio;
      } else if (this.isStatus1 == true) {
        this.radioNum = this.radio1;
      } else if (this.isStatus2 == true) {
        this.radioNum = this.radio2;
      }
      console.log("输出单选框选择：", this.rowId, this.radioNum);
      this.$confirm("是否保存, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        apiShout
          .reConfirm({ id: this.rowId, state: this.radioNum })
          .then((res) => {
            if (res.data.code == 200) {
              this.dialogVisibleS = false;
              this.$message({
                type: "success",
                message: "处理成功!",
              });
              this.getList();
            } else {
              this.$message.error(res.data.msg);
            }
          });
      });
    },
    clearUserForm() {
      this.dialogVisible = false;
    },
    clearUserFormS() {
      this.dialogVisibleS = false;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    handleCloseS() {
      this.dialogVisibleS = false;
    },
    imaClick() {
      // this.getList();
      apiShout
        .recharge({
          pageSize: this.pageSize,
          pageCount: 1,
          startTime:
            this.overallForm.time == null ? "" : this.overallForm.time[0],
          endTime:
            this.overallForm.time == null ? "" : this.overallForm.time[1],
          number: this.SerachInput.number,
          paymentUserPhone: this.SerachInput.paymentUserPhone,
          orderNo: this.SerachInput.orderNo,
          type: this.SerachInput.type,
          state: this.SerachInput.state,
        })
        .then((res) => {
          console.log("列表：", res.data);
          if (res.data.code == 200) {
            this.tableData = res.data.result.data;
            this.totalCount = res.data.result.page.totalCount;
            this.pageSize = res.data.result.page.pageSize;
            this.amount = res.data.result.amount;
            this.pageCount = res.data.result.page.pageCount;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    situa() {
      api.getSelectProduct({ dictCode: "rechargerecordtype" }).then((res) => {
        //console.log("下拉框：", res.data);
        if (res.data.code == 200) {
          this.opProduct = res.data.result;
        }
      });
    },
    getList() {
      apiShout
        .recharge({
          pageSize: this.pageSize,
          pageCount: this.pageCount,
          startTime:
            this.overallForm.time == null ? "" : this.overallForm.time[0],
          endTime:
            this.overallForm.time == null ? "" : this.overallForm.time[1],
          number: this.SerachInput.number,
          paymentUserPhone: this.SerachInput.paymentUserPhone,
          orderNo: this.SerachInput.orderNo,
          type: this.SerachInput.type,
          state: this.SerachInput.state,
        })
        .then((res) => {
          console.log("列表：", res.data);
          if (res.data.code == 200) {
            this.tableData = res.data.result.data;
            this.totalCount = res.data.result.page.totalCount;
            this.pageSize = res.data.result.page.pageSize;
            this.amount = res.data.result.amount;
            this.pageCount = res.data.result.page.pageCount;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    //导出
    exportList() {
      let that = this;
      that
        .$http({
          url: "/too/center/rechargeRecod/exportRechargeRecodList",
          params: {
            startTime:
              this.overallForm.time == null ? "" : this.overallForm.time[0],
            endTime:
              this.overallForm.time == null ? "" : this.overallForm.time[1],
            number: this.SerachInput.number,
            paymentUserPhone: this.SerachInput.paymentUserPhone,
            orderNo: this.SerachInput.orderNo,
            type: this.SerachInput.type,
            state: this.SerachInput.state,
          },
          method: "get",
          responseType: "blob",
          headers: {
            token: JSON.parse(localStorage.getItem("token")),
          },
        })
        .then((res) => {
          // console.log(res,"查询")
          console.log("导出数据：", res.data);
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
            crossOrigin: "Anonymous",
          });
          const a = document.createElement("a");
          let date = new Date();
          var year = date.getFullYear();
          var month =
            date.getMonth() + 1 < 10
              ? "0" + (date.getMonth() + 1)
              : date.getMonth() + 1;
          var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
          var hours =
            date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
          var minutes =
            date.getMinutes() < 10
              ? "0" + date.getMinutes()
              : date.getMinutes();
          var seconds =
            date.getSeconds() < 10
              ? "0" + date.getSeconds()
              : date.getSeconds();
          // 拼接
          let ds =
            year +
            "-" +
            month +
            "-" +
            day +
            " " +
            hours +
            ":" +
            minutes +
            ":" +
            seconds;
          document.body.appendChild(a);
          a.style.display = "none";
          // 使用获取到的blob对象创建的url
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          // 指定下载的文件名
          a.download = "付费管理" + ds + ".xls";
          a.click();
          document.body.removeChild(a);
          // 移除blob对象的url
          window.URL.revokeObjectURL(url);
        });
    },
    //分页
    handleSizeChange(size) {
      //console.log("size:", size);
      this.pageSize = size;
      this.getList();
    },
    //当前在第几页
    handleCurrentChange(pageCount) {
      //console.log("pageCount:", pageCount);
      this.pageCount = pageCount;
      this.getList();
    },
  },
};
</script>

<style lang='less' scoped>
.inforOne {
  display: flex;
  margin-bottom: 20px;
}
.inforInput {
  width: 582px;
}
.inforDian {
  font-size: 18px;
  // font-weight: 700;
  width: 90px;
  // margin-top: 5px;
}
.police {
  margin-left: 10px;
}
.senOne {
  margin-right: 25px;
}
.xian1 {
  width: 103%;
  height: 2px;
  border-bottom: 1px solid #f3f3f3;
  margin-top: 30px;
  margin-bottom: 20px;
}
.baocun {
  background: hsl(3deg 31% 57%);
  border: none;
  padding: 8px 25px;
  font-size: 21px;
  color: #fff;
  border-radius: 30px;
}
/deep/ .el-dialog__body {
  padding: 0;
  margin-left: 37px;
  margin-top: 24px;
}

.amount {
  margin-left: 20px;
}
.timeRight {
  margin-right: 15px;
}
.timeRightOne {
  margin-right: 23px;
}
.timeRightOne1 {
  margin-right: 62px;
}
/deep/ .el-input.is-disabled .el-input__inner {
  font-size: 16px;
}
.voucher {
  width: 250px;
  height: 150px;
}
/deep/.el-date-editor .el-range-separator {
  height: auto;
}
/deep/.el-input__icon {
  height: auto;
}
/deep/.el-input__inner {
  height: 33px;
  line-height: 33px;
}
/deep/.el-input__icon {
  line-height: 33px;
}
/deep/.el-table th.el-table__cell > .cell {
  font-size: 14px;
  color: #909399;
}
/deep/.el-table td.el-table__cell div {
  color: #606266;
  font-size: 14px;
}
/deep/.el-table .el-table__cell {
  text-align: center;
}
/deep/.el-pager {
  margin-top: 5px;
}
.program {
  position: absolute;
  height: 100%;
  width: 100%;
  // 列表
  .programlist {
    margin-left: 25px;
    margin-right: 40px;
    // 上
    .protop {
      position: relative;
      margin-top: 25px;
      height: auto;
      margin-right: 40px;
      display: flex;
      align-items: center;
      span {
        margin-right: 10px;
        color: #909399;
        font-size: 14px;
        font-weight: bold;
      }
      // input框
      .el-input__inner {
        border-radius: 0;
        border: 1px solid #e1e1e1;
      }
      // 知识分类
      .knowledge {
        position: absolute;
        top: 3px;
        .el-input {
          width: 190px;
          margin-right: 0px;
        }
      }
      // 播放次数
      .play {
        position: absolute;
        left: 300px;
        top: 3px;
        .el-input {
          width: 134px;
        }
      }
      // 关键词
      .antistop {
        position: absolute;
        left: 700px;
        top: 3px;
        display: flex;
        align-items: center;
        .spanguan {
        }
        .el-input {
          width: 230px;
        }
        .buttons {
          margin-left: 14px;
        }
      }
    }
    // table
    .tables {
      margin-top: 10px;
      .el-table::before {
        height: 0;
      }
      .el-table {
        td,
        th.is-leaf {
          border: none !important;
        }
        tr th {
          font-size: 19px;
          color: #a8a8a8;
          text-align: center;
        }
        tr td {
          text-align: center;
          font-size: 17px;
          color: #a8a8a8;
        }
      }
      .buts {
        width: 36px;
        height: 27px;
      }
      .bu {
        margin-right: 20px;
        margin-left: 20px;
      }
    }
    /deep/ .el-table .el-table__cell {
      padding: 12px 0;
      min-width: 0;
      box-sizing: border-box;
      text-overflow: ellipsis;
      vertical-align: middle;
      position: relative;
      text-align: center;
    }
    /deep/ .el-table td.el-table__cell,
    .el-table th.el-table__cell.is-leaf {
      border-bottom: none;
    }
    /deep/ th.el-table__cell.is-leaf {
      border-bottom: none;
    }
    /deep/ .el-table td,
    .el-table th.is-leaf {
      border-bottom: none;
    }
    /deep/ .el-table td,
    .el-table th.is-leaf {
      border-bottom: none !important;
    }
    // 总结
    .total {
      display: flex;
      justify-content: space-between;
      margin-right: 0px;
      margin-top: 30px;
      margin-left: 34px;
      p {
        font-size: 15px;
        display: inline-block;
        margin-right: 36px;
        color: #606266;
      }
      .spanto {
        color: #92aca7;
        margin-left: 17px;
        margin-right: 12px;
      }
    }
  }
}
/deep/.el-pager li {
  padding: 0 4px;
  font-size: 13px;
  min-width: 35.5px;
  height: 28px;
  line-height: 15px;
  box-sizing: border-box;
  text-align: center;
}
.bottom {
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  margin-top: 10px;
}
.bottom-right {
  float: right;
  margin-right: 20px;
}
.bottom-left {
  position: relative;
  //   display: flex;
}
// 按钮
.buttons {
  display: flex;
  justify-content: flex-end;
  // margin-right: 110px;
  .el-button {
    width: 130px;
    height: 40px;
    line-height: 40px;
    border-radius: 25px;
    margin-top: 20px;
    background: #d78673;
    color: #ffffff;
    font-size: 16px;
    padding: 0px;
  }
}
/deep/.el-table td,
.el-table th {
  padding: 12px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: center !important;
}
/deep/.el-table .cell {
  text-align: center !important;
}
/deep/.el-table td,
.el-table th.is-leaf {
  border-bottom: none !important;
}
/deep/.el-table thead tr th.is-leaf {
  border: none;
  border-right: none;
}
/deep/.el-form-item__label {
  color: #909399;
}
</style>